<template>
	<div class="container-fluid">
		<div class="row mb-3 align-items-center">
			<div class="col-12 mb-2">
				<router-link to="/">Home</router-link>
			</div>
			<div class="col-12">
				<h2 class="d-inline">Shiny Stories</h2><sub>BETA</sub>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-sm-6">
				A project developed out of a passion for Pokémon games, an interest in mathematics, and a desire to
				further software development skills. The aim of this application is to allow users to keep track
				of all of their shiny hunts across different Pokémon games in one place. Planned features include
				more social features as well as a realtime board of other users&apos; shiny hunt updates.
			</div>
			<div class="col-12 col-sm-6 mt-3 mt-sm-0">
				<ul>
					<li>
						Inspired by Tim Gourley&apos;s
						<a
							href="https://shinytrack.night.coffee/#/shiny/setup"
							target="_blank"
							rel="noopener noreferrer"
						>
							Shiny Tracker
						</a>
					</li>
					<li>
						Pokémon sprites and shiny odds sourced from
						<a
							href="https://www.serebii.net"
							target="_blank"
							rel="noopener noreferrer"
						>
							Serebii.net
						</a>
					</li>
					<li>Developed by Pranav Chary</li>
					<li>
						Please contact <a href="mailto:pranav@pranavchary.com">pranav@pranavchary.com</a> with any
						questions, comments, or feedback
					</li>
				</ul>
			</div>
		</div>
		<div class="row my-3">
			<div class="col-12 col-sm-6">
				<h4>What is a binomial distribution?</h4>
			</div>
			<div class="col-12">
				<p>
					A binomial distribution is a probability distribution that allows us to predict
					the chances of a particular outcome when the same action is repeated multiple times.
					As the prefix &quot;bi&quot; indicates, the action must be an independent event that can only
					have two outcomes (usually success and failure). The most common example of this type of event
					is a coin flip, where the coin will only ever land on heads or tails and one coin flip does not
					affect the outcome of any others.
				</p>
				<p>
					As it relates to shiny hunting, the binomal distribution is a great way to represent the
					likelihood that a Pokémon you encounter (or hatch) will be shiny. This is because we are only
					concerned with two distinct outcomes: shiny and not shiny, and one Pokémon being shiny or not
					has no effect on any others. To calculate the probability of finding AT LEAST one shiny Pokémon,
					we look at the cumulative binomial probability.
				</p>
				<p>
					It is important to keep in mind that this probability is NOT the same as the odds to encounter
					a shiny Pokémon. The best way to think of the cumulative binomial probability in this case is
					how close to &quot;full odds&quot; you are to finding your desired shiny Pokémon. At the end
					of the day, it&apos;s simply a numbers game and the randomness could either work in your
					favor or against you. You could find your shiny in as little as 1 encounter, or be so unlucky
					as to not find it after 10,000 encounters! If you encounter enough Pokémon to pass 100% by
					quite a bit and still haven&apos;t encountered a shiny one, it might be best to do something
					else for a bit and come back to it later.
				</p>
				<p>
					It is said that the definition of insanity is doing the same thing repeatedly and expecting
					different results. Hopefully this was an interesting and informative read. Now get back out
					there and be insane; You&apos;ll find the shiny you&apos;re looking for one day.
				</p>
			</div>
		</div>
		<div class="row mt-sm-2 mb-3">
			<div class="col-12 text-muted">
				<small>
					DISCLAIMER: I do not claim ownership of Pokémon or any related names and images.
					Shiny Stories is a third-party fan-made application and is not in any way affiliated with
					the Pokémon franchise, The Pokémon Company, Nintendo, or Game Freak. Pokémon and all related names are trademark and copyright of Nintendo {{ `1996 - ${new Date().getFullYear()}.` }}
				</small>
			</div>
		</div>
	</div>
</template>